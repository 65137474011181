<template>
	<div class="auth-register">
		<div class="auth-register__header">
			<span>Already have an account? &nbsp;</span>
			<router-link :to="{ name: 'login' }" class="button button--unelevated button--secondary button--medium">Login</router-link>
		</div>

		<form-register class="auth-register__form" />
	</div>
</template>

<script>
import FormRegister from '@/components/FormRegister'

export default {
	name: 'AuthRegister',
	components: {
		FormRegister,
	},
	metaInfo: () => ({
		title: 'Request Access',
	}),
}
</script>

<style scoped lang="scss">
.auth-register {
	display: flex;
	flex-direction: column;

	&__form {
		@include modules.gutter('padding');
		flex: 1;
		max-width: 840px;
		width: 100%;

		@include modules.media-query('phone') {
			@include modules.gutter('padding', 0);
		}
	}

	&__header {
		@include modules.gutter('margin-bottom');

		text-align: right;

		span {
			@include modules.gutter('padding-right');
			flex: 1;
		}

		@include modules.media-query('phone') {
			align-items: center;
		}
	}
}
</style>
