<template>
	<div class="partial partial--auth-login auth-login">
		<div class="auth-login__header">
			<span>Don't have an account? &nbsp;</span>
			<router-link :to="{ name: 'register' }" class="button button--unelevated button--secondary button--medium">Request Access</router-link>
		</div>
		<form-login class="auth-login__form" />
	</div>
</template>

<script>
import FormLogin from '@/components/FormLogin'

export default {
	name: 'AuthLogin',
	components: {
		FormLogin,
	},
}
</script>

<style scoped lang="scss">
.auth-login {
	display: flex;
	flex-direction: column;

	&__form {
		@include modules.gutter('padding');
		max-width: 840px;
		width: 100%;

		@include modules.media-query('phone') {
			@include modules.gutter('padding', 0);
		}
	}

	&__header {
		@include modules.gutter('margin-bottom');

		text-align: right;

		span {
			@include modules.gutter('padding-right');
			flex: 1;
		}

		@include modules.media-query('phone') {
			align-items: center;
		}
	}
}
</style>
